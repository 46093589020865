/*
 * @Date: 2023-03-14 14:07:15
 * @LastEditors: simLarry
 * @LastEditTime: 2023-03-28 18:30:20
 * @FilePath: \J1-M1-QRP-CODE\components\download\index.js
 */
import React from "react";
import { ApiPort } from "../../actions/API";
import { fetchRequest } from "../../config/Fatch.config";
import QRCode from 'qrcode';
import { getAffFromCookie } from "../../actions/util";
class Register extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            nativeAppUrl : props.nativeAppUrl ,
            qrpic:''
        }


        
    }
   
    componentWillReceiveProps(nextProps , prevState){
        if(nextProps.nativeAppUrl !== prevState.nativeAppUrl){
            this.getQrcodeUrl(nextProps.nativeAppUrl);
        }
        return ;
    }
    // static getDerivedStateFromProps(nextProps , prevState){
    //     if(nextProps.nativeAppUrl !== prevState.nativeAppUrl){
    //         console.log(prevState);
    //         return {nativeAppUrl : nextProps.nativeAppUrl};
    //     }
    //     return null;
    // }


    checkURL(url){
        let aff  ;
        let startIndex = 0;
        if (url.indexOf('#') !== -1) {
            startIndex = url.indexOf('#');
        }
    
        if(url.indexOf('?',startIndex) === -1){

            aff = getAffFromCookie() && '?aff='+getAffFromCookie();
            
        }else{
            if(url.includes("?aff")){
                aff = '';
            }else{
                aff = getAffFromCookie() && '&aff='+getAffFromCookie();
            }
        }
        return aff;
    }


    async getQrcodeUrl(nativeAppUrl){
        let aff = this.checkURL(nativeAppUrl);        

        if(typeof nativeAppUrl === 'string' && nativeAppUrl !== ''){
            let database = await QRCode.toDataURL(nativeAppUrl + aff);
            this.setState({
                qrpic : database
            })
        }
    }


    

    goPath(){
        let { nativeAppUrl } = this.props;

        let aff = this.checkURL(nativeAppUrl);  
              
        global.location.href = nativeAppUrl + aff;
    }


    render(){
        let { qrpic } = this.state;
        let { nativeAppUrl } = this.props;
        
        return (
            <>
                <div className="register-box web-qrcode">
                    <div className="box-head">
                        <p>竞博客户端下载</p>
                    </div>

                    <div className="qrcode-content">
                        <img style={{width:'9rem',marginRight:'14%' , marginLeft:'7%'}} src={qrpic} />
                        <div className="Down">
                        {/*  */} 
                        <ol className="installation">
                            <li>下载游戏客户端，</li>
                            <li>让你更顺畅的进入我们的JBO网站。</li>
                            <li>若要更新版本，请先卸载旧的APP版本</li>
                        </ol>

                            {/* IOS 点击下载 */}
                            <div className="btnDownload" onClick={()=>{this.goPath()}}>
                                <img src="/static/images/ios.png" />
                            </div>
                            {/* Android 点击下载 */}
                            <div className="btnDownload" onClick={()=>{this.goPath()}}>
                                <img src="/static/images/android.png" />
                            </div>
                        </div>

                        
                    </div>

                    
                </div>


                {/*  Phone Ipad */}

                <div className="register-box mobile-qrcode">
                    <div className="box-head">
                        <p>竞博客户端下载</p>
                    </div>

                    <div className="qrcode-content">
                        <img style={{width:'6rem',marginRight:'5%' ,}} src={qrpic} />
                        <div className="Down">
                        {/*  */} 
                        <ol className="installation">
                            <li>下载游戏客户端，</li>
                            <li>让你更顺畅的进入我们的JBO网站。</li>
                            <li>若要更新版本，请先卸载旧的APP版本</li>
                        </ol>

                            {/* IOS 点击下载 */}
                            <div className="btnDownload" onClick={()=>{this.goPath()}}>
                                <img src="/static/images/ios.png" />
                            </div>
                            {/* Android 点击下载 */}
                            <div className="btnDownload" onClick={()=>{this.goPath()}}>
                                <img src="/static/images/android.png" />
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}


export default Register;