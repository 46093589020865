import React from "react";
import { _handleTheMainSiteUrlOfBackendResponse } from "../../actions/util";

export default class DownHeader extends React.Component{
    constructor(){
        super();
        this.state = {
            
        }
        this.GoPage = this.GoPage.bind(this);
    }

    GoPage(link){
        global.location.href = link;
    }

    render(){
        let { showHeader , nativeAppUrl , mainSiteUrl }  = this.props;
        console.log('showDownHeader===',showHeader);
        return(
            <>
                {showHeader && <div className="APPDownload">
                    <div className="APPDownload-2">
                        <p className="app_header_close" onClick={()=>{this.props.hideHeader(false)}}>x</p>
                        <img className="APPlogo" src="/static/images/Fun88NativeLogo.png" />
                        <div className="app_banner_info">
                            <p className="app_banner_title">乐天堂APP</p>
                            <p>客户端下载</p>
                            <p className="star">
                                <img className="app_banner_rating" src="/static/images/star.png"/>
                                <img className="app_banner_rating" src="/static/images/star.png"/>
                                <img className="app_banner_rating" src="/static/images/star.png"/>
                                <img className="app_banner_rating" src="/static/images/star.png"/>
                                <img className="app_banner_rating" src="/static/images/star.png"/>

                            </p>
                        </div>
                    </div>

                    <div className="button-link">
                        <p className="website"  onClick={()=>{this.GoPage(mainSiteUrl)}}>官网</p>
                        <p className="downlink" onClick={()=>{this.GoPage(nativeAppUrl)}}>下载</p>
                    </div>
                </div>}
            </>
        )
    }
}